import type { UploadOptions } from './Types.ts';

import { default as FileUpload, ProgressHandler } from './FileUpload.ts';
import { zeroBytesFilesAlert, dataURIToFile, blobToFile } from './FileUploadUtils.ts';
import { $t } from 'o365-utils';

export default class FileUploadControl {
    private _files: Array<File> | undefined | null;
    private _fileUpload: FileUpload;
    progress: ProgressHandler;

    beforeUpload: Function | undefined;
    onCompleted: Function | undefined;

    uploading: boolean = false;

    set files(pList) {
        if (pList && pList.constructor === FileList) {
            this._files = Array.from(pList);
        } else {
            this._files = pList;
        }
    }

    get files() {
        return this._files;
    }

    set url(pValue: string) {
        this._fileUpload.url = pValue;
    }

    set useChunks(pValue: boolean) {
        this._fileUpload.useChunks = pValue;
    }

    constructor(pOptions: FileUploadOptions) {
        this._fileUpload = new FileUpload({
            url: pOptions.url,
            useChunks: true
        })

        this.progress = new ProgressHandler();
    }

    async upload(pOptions: UploadOptions) {
        if (!pOptions.files) {
            return;
        }

        if (zeroBytesFilesAlert(pOptions.files)) {
            return;
        }

        if (typeof pOptions.files[0] === 'string') {
            pOptions.files[0] = dataURIToFile(pOptions.files[0])!;
        }

        if (pOptions.files[0] instanceof Blob && !(pOptions.files[0] instanceof File)) {
            pOptions.files = blobToFile(pOptions.files as Blob[]);
        }

        this.files = pOptions.files as File[];

        const vUploads = [];
        let vBeforeUploadParams: any

        if (!this._files) return Promise.resolve();
        if (!this._files.length) return Promise.resolve();
        if (this._files.length === 0) return Promise.resolve();

        this.uploading = true;
        this.progress.start(pOptions);
        this.progress.message = $t(`Starting to upload ${this.files?.length} files`);

        let vData: any = {};

        if (this.beforeUpload) vBeforeUploadParams = this.beforeUpload.call(this, ...arguments);
        if (vBeforeUploadParams === false) return Promise.resolve();
        if (vBeforeUploadParams && typeof vBeforeUploadParams == 'object') {
            Object.keys(vBeforeUploadParams).forEach(key => {
                vData[key] = vBeforeUploadParams[key];
            })
        }

        vBeforeUploadParams = null;

        if (pOptions.beforeUpload) vBeforeUploadParams = pOptions.beforeUpload.call(this, ...arguments);
        if (vBeforeUploadParams === false) return Promise.resolve();
        if (vBeforeUploadParams && typeof vBeforeUploadParams == 'object') {
            Object.keys(vBeforeUploadParams).forEach(key => {
                vData[key] = vBeforeUploadParams[key];
            })
        }

        vBeforeUploadParams = null;

        if (pOptions.beforeUploadAsync) {
            await pOptions.beforeUploadAsync.call(this, vBeforeUploadParams).then((res: Object) => {
                vBeforeUploadParams = res;
            }).catch((ex: any) => {
                if (pOptions.onError) pOptions.onError.call(this, ...arguments);
                this.progress.message = $t('Error');
                this.uploading = false;
                return Promise.reject(ex);
            })
        }
        if (vBeforeUploadParams === false) return Promise.resolve();
        if (vBeforeUploadParams && typeof vBeforeUploadParams == 'object') {
            Object.keys(vBeforeUploadParams).forEach(key => {
                vData[key] = vBeforeUploadParams[key];
            })
        }

        for (let i = 0; i < this._files.length; i++) {
            let vFile = this._files[i];

            let fileData = vData;

            vUploads.push(this.uploadFile(vFile, fileData));
        }

        return Promise.all(vUploads).then((data: any) => {
            if (pOptions.onCompleted) pOptions.onCompleted.call(this, data, ...arguments);
            if (this.onCompleted) this.onCompleted.call(this, ...arguments);

            if (!data || !data[data.length - 1]) return;
            this.uploading = false;

            this.progress.message = $t('Upload complete');
            this.progress.completeUpload();
            return data;
        }).catch((ex) => {
            if (pOptions.onError) pOptions.onError.call(this, ex, ...arguments);
            this.progress.message = $t('Error');
            this.uploading = false;
        })
    }

    cancel() {
        this.uploading = false;
        this.files = null;
        this.progress.message = $t('Canceling upload');
        this._fileUpload.abort();
    }

    async uploadFile(pFile: File, pData: any) {
        try {
            const vRef = await this._uploadFile(pFile, pData);
            pData["FileRef"] = vRef['FileRef'];
            this.progress.message = $t(`Uploading file: ${pFile.name}`);
            return { ...pData, ...vRef };
        } catch (ex) {
            this.progress.updateError(pFile, `${$t('Upload failed')}: ${ex}`);
        }
    }

    private async _uploadFile(pFile: File, pData: any) {
        return await this._fileUpload.upload({
            file: pFile,
            data: pData,
            onProgress: (e: any) => this.progress.updateProgress(pFile, e)
        });
    }
}

type FileUploadOptions = {
    url: string,
    useChunks: boolean,
}